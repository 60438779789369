import {Component, Input } from '@angular/core';
import {ApiService} from '../api/api.services';

@Component ({
    selector: 'Login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less']
})
class LoginPageComponent {
    username = '';
    password = '';

    carouselItems = [
        { src: '../../assets/images/home/mini_page_bg.jpeg', class: 'carousel-image' },
        { src: '../../assets/images/home/mini_page_bg1.jpeg', class: 'carousel-image' },
        { src: '../../assets/images/home/mini_page_bg2.jpeg', class: 'carousel-image' }
    ];

    constructor(private readonly api: ApiService) {


    }

    goToSlide(index: number): void {
        // Implement slide navigation logic here
      }

    login(): void {
        this.api.login(this.username, this.password);

    }
}

export {LoginPageComponent};
