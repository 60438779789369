import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';

@NgModule({
    declarations: [ HeaderComponent ],
    imports: [ BrowserModule, RouterModule ],
    exports: [ HeaderComponent, RouterModule ],
    providers: [],
    bootstrap: [ HeaderComponent ]
})
export class HeadersModule {}
