import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FabricModule } from '../app/fabric.module';
import { HomePageComponent } from './home/home.component';
import { AboutPageComponent } from './about/about.component';
import { PortfolioPageComponent } from './portfolio/portfolio.component';
import { ContactPageComponent } from './contact/contact.component';
import { LoginPageComponent } from './login/login.component';
import { ChatbotPageComponent } from './chatbot/chatbot.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReportPageComponent } from './report/report.component';

@NgModule({
    declarations: [
        HomePageComponent,
        AboutPageComponent,
        PortfolioPageComponent,
        ContactPageComponent,
        LoginPageComponent,
        ReportPageComponent,
        ChatbotPageComponent
    ],
    imports: [CommonModule, BrowserModule, FabricModule, FormsModule, HttpClientModule],
    exports: [
        HomePageComponent,
        AboutPageComponent,
        PortfolioPageComponent,
        ContactPageComponent,
        LoginPageComponent,
        ReportPageComponent,
        ChatbotPageComponent
    ]
})
class PagesModule {}

export { PagesModule };
