import {Component, Input } from '@angular/core';

@Component ({
    selector: 'About',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.less']
})
class AboutPageComponent {

}

export {AboutPageComponent};