import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

export interface ApiUser {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
}

@Injectable()
export class ApiService {

  baseUrl = '/ca-studios-rest';

  constructor(private readonly http: HttpClient) {}

  login(username: string, password: string): Observable<HttpResponse<ApiUser>> {
    const formData: HttpParams = new HttpParams()
    .append('username', username)
    .append('password', password);

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post<ApiUser>(this.baseUrl + '/login', formData.toString(), { headers, observe: 'response' });
  }

  async queryInternet(data: any): Promise<any> {
    const response = await fetch(
        '/api/ping1',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
    );
    return await response.json();
  }

  async queryChris(data: any): Promise<any> {
    const response = await fetch(
        '/api/ping2',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
    );
    return await response.json();
  }
}
