<div class="body">
    <div class="element-row">
        <div class="col-xs-12 body-container1">
            <div>
                <img src="../../assets/images/chatbot/chatbot-img1.jpeg" class="body-img">
                <div class="text-box-logo">
                    <h1>caStudios.tech</h1>
                    <h3>Chatbot Room</h3>
                    <div class="text-box">
                        <p>Please try the chatbot and ask questions and see if it can answer you. See this space as a chatbot room hehehe...</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>