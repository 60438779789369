import {Component, Input } from '@angular/core';

@Component ({
    selector: 'divider',
    templateUrl: './divider.component.html'
})
class DividerComponent {

    @Input()
    borderColor = 'red';
    @Input()
    borderSize = '1';
    
    setBorderColor = () => {
        let styles = {
            'border': this.borderSize + 'px solid', 
            'border-image': 'linear-gradient(90deg, rgba(0,0,0,0), ' + this.borderColor + ', rgba(0,0,0,0)) 1', 
            'border-left': 'none', 
            'border-top': 'none',
            'border-right': 'none'
          };
        return styles;
    }

}

export {DividerComponent};