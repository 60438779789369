<div class="body">
    <div class="element-row">
        <div class="col-xs-12 body-container1 container-gradient">
            <div class="col-sm-6">
                <div class="text-box-logo">
                    <h1>caStudios.tech - Portfolio</h1>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="text-box"></div>
            </div>
        </div>
        <div class="col-xs-12 body-container2">
            <div class="col-sm-4">
                <div>
                    <h2>01</h2>
                </div>
            </div>
            <div class="col-sm-8">
                <img src="../../assets/images/experience/report-app-part1.png" class="body-img">
            </div>
        </div>
        <div class="col-xs-12 body-container3">
            <div class="col-sm-8">
               <img src="../../assets/images/experience/report-app-part2.png" class="body-img">
            </div>
            <div class="col-sm-4">
                <div>
                    <h2>02</h2>
                </div>
            </div>
        </div>
        <div class="col-xs-12 body-container4">
            <div class="col-sm-4">
                <div>
                    <h2>03</h2>
                </div>
            </div>
            <div class="col-sm-8">
                <img src="../../assets/images/experience/report-car.png" class="body-img">
            </div>
        </div>
        <div class="col-xs-12 body-container5 container-gradient-back">
            <div class="text-box-logo">
                <h3>More to come!</h3>
            </div>
        </div>
    </div>
</div>