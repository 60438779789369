import {Component, Input } from '@angular/core';

@Component ({
    selector: 'Chatbot',
    templateUrl: './chatbot.component.html',
    styleUrls: ['./chatbot.component.less']
})
class ChatbotPageComponent {

}

export {ChatbotPageComponent};