import {Component, Input } from '@angular/core';

@Component ({
    selector: 'Portfolio',
    templateUrl: './portfolio.component.html',
    styleUrls: ['./portfolio.component.less']
})
class PortfolioPageComponent {

}

export {PortfolioPageComponent};