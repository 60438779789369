import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FabricModule } from './fabric.module';
import { FormsModule } from '@angular/forms';
import { HeadersModule} from './headers.module';
import { PagesModule} from './pages.module';
import { RouterModule } from '@angular/router';
import { ApiService } from './api/api.services';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, FabricModule, FormsModule, HeadersModule, PagesModule, RouterModule],
  providers: [ApiService],
  bootstrap: [AppComponent]

})
export class AppModule { }
