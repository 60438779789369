import {Component, Input } from '@angular/core';

@Component ({
    selector: 'Contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.less']
})
class ContactPageComponent {

}

export {ContactPageComponent};