import {Component, Input } from '@angular/core';

@Component ({
    selector: 'Home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.less']
})
class HomePageComponent {

      carouselItems = [
        { src: '../../assets/images/home/mini_page_bg.jpeg', class: 'carousel-images' },
        { src: '../../assets/images/home/mini_page_bg1.jpeg', class: 'carousel-images'},
        { src: '../../assets/images/home/mini_page_bg2.jpeg', class: 'carousel-images'}
    ]

    item = [
        {
          title: 'Chatbot',
          imgSrc: '../assets/images/home/mini_page_bg.jpeg',
          // description: 'This is me!',
          // information: 'Read more by clicking this'
        },
        {
          title: 'Portfolio',
          imgSrc: '../assets/images/home/mini_page_bg2.jpeg',
          // description: 'This is my future!',
          // information: 'Read more by clicking this'
        },
        {
          title: 'Contact',
          imgSrc: '../assets/images/home/mini_page_bg1.jpeg',
          // description: 'This is me!',
          // information: 'Read more by clicking this'
        }

      ];

goToSlide(index: number): void {
    // Implement slide navigation logic here
  }
  
}

export {HomePageComponent};

