import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'Card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.less']
})
class Card implements OnInit {
    

    @Input()title:String | undefined;
    @Input()imgSrc:String | undefined;
    @Input()backgroundColor:String | undefined;
    @Input()cardHeight:number | undefined;
    @Input()cardWidth:number | undefined;
    imgStyleHeight: String | undefined;
    imgStyleWidth: String | undefined;
    cardStyle: any | undefined;
    
    ngOnInit(): void {

        const height = this.cardHeight ? this.cardHeight : 500;
        const width = this.cardWidth ? this.cardWidth : 240;
        
        this.cardStyle = {
            'background-color': this.backgroundColor,
            'height': height + 'px',
            'width' : width + 'px'
            //'width' : this.cardWidth ? this.cardWidth + 'px' : '100px' 
        }
        this.imgStyleHeight = height - 100 + 'px',
        this.imgStyleWidth =  width - 2 + 'px'
    }
}

export { Card };