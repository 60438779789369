<div class="body-container">
    <div class="row no-margin">
        <div class="col-xs-12 body-top-container"></div>
    </div>
    <div class="row no-margin">
        <div class="col-xs-12">
            <div class="row row-no-padding container-1">
                <div class="container-1-gradient">
                    <div class="col-sm-2">
                        <div class="container-sub-1a"></div>
                    </div>
                    <div class="col-sm-4">
                        <div class="container-sub-1b module-border-wrap">
                            <div class="text-box module">
                                <h1>Register</h1>
                                <div class="text-css">
                                    <p>Please create a user and a password:</p>
                                    <label for="firstname"><b>Firstname</b></label>
                                    <br>
                                    <input type="text" placeholder="Enter Firstname" name="firstname" id="reg_firstname" required>
                                    <br>
                                    <label for="lastname"><b>Lastname</b></label>
                                    <br>
                                    <input type="text" placeholder="Enter Lastname" name="lastname" id="reg_lastname" required>
                                    <br>
                                    <label for="email"><b>Email:</b></label>
                                    <br>
                                    <input type="text" placeholder="Enter Email" name="email" id="reg_email" required>
                                    <br>
                                    <label for="psw"><b>Password:</b></label>
                                    <br>
                                    <input type="password" placeholder="Enter Password" name="psw" id="reg_psw" required>
                                    <br>
                                    <label for="psw-repeat"><b>Repeat Password:</b></label>
                                    <br>
                                    <input type="password" placeholder="Repeat Password" name="psw-repeat" id="psw-repeat" required>
                                    <br>
                                    <button class="btn btn-primary" (click)="login()">Register</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="container-sub-1c module-border-wrap">
                            <div class="text-box module">
                                <h1>Login</h1>
                                <div class="text-css">
                                    <p>Please sign in with your credentials:</p>
                                    <label for="email"><b>Email:</b></label>
                                    <br>
                                    <input [(ngModel)]="username" type="text" placeholder="Enter Email" name="email" id="email" required>
                                    <br>
                                    <label for="psw"><b>Password:</b></label>
                                    <br>
                                    <input [(ngModel)]="password" type="password" placeholder="Enter Password" name="psw" id="psw" required>
                                    <br>
                                </div>
                                <button class="btn btn-primary" (click)="login()">Login</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="container-sub-1d"></div>
                    </div>
                </div>    
            </div>
        </div>
    </div>
    <div class="row no-margin">
        <div class="col-xs-12">
            <div class="row row-no-padding container-2">  
                <div class="col-sm-2">
                    <div class="container-sub-2a"></div>
                </div>
                <div class="col-sm-4">
                </div> 
                <div class="col-sm-2">
                    <div class="container-sub-2c">
                        <div class="row no-margins"></div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="container-sub-2d">
                        <div class="row no-margins">
                            <div class="col-sm-6 no-margins container-child-1b">
                                <img class="image-size" src="../../assets/images/home/ai-generated-1.jpeg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>