import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home/home.component';
import { AboutPageComponent } from './about/about.component';
import { PortfolioPageComponent } from './portfolio/portfolio.component';
import { ContactPageComponent } from './contact/contact.component';
import { LoginPageComponent } from './login/login.component';
import { ReportPageComponent } from './report/report.component';
import { ChatbotPageComponent } from './chatbot/chatbot.component';


const routes: Routes = [
  { path: '', pathMatch: 'full' , redirectTo: 'home'},
  { path: 'home', component: HomePageComponent},
  { path: 'About', component: AboutPageComponent},
  { path: 'Portfolio', component: PortfolioPageComponent},
  { path: 'Contact', component: ContactPageComponent},
  { path: 'Login', component: LoginPageComponent},
  { path: 'Report', component: ReportPageComponent},
  { path: 'Chatbot', component: ChatbotPageComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [
  HomePageComponent,
  AboutPageComponent,
  PortfolioPageComponent,
  ContactPageComponent,
  LoginPageComponent,
  ReportPageComponent,

];
