import {Component, Input } from '@angular/core';

@Component ({
    selector: 'Report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.less']
})
class ReportPageComponent {
    carouselItems = [
        { src: '../../assets/images/home/mini_page_bg.jpeg', class: 'carousel-image' },
        { src: '../../assets/images/home/mini_page_bg1.jpeg', class: 'carousel-image' },
        { src: '../../assets/images/home/mini_page_bg2.jpeg', class: 'carousel-image' }
    ]

    goToSlide(index: number): void {
        // Implement slide navigation logic here
      }
}

export {ReportPageComponent};