import { Component } from '@angular/core';

@Component ({
    selector: 'Header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less']
})
class HeaderComponent {

    activeTab:string='home';
    setActiveTab(activeTab:string){
        console.log('Blablabla');
        this.activeTab=activeTab;
    }
    
}

export {HeaderComponent};