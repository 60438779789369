<div class="body">
    <div class="element-row row-no-padding ">
        <!-- Head -->
        <div class="col-xs-12 body-container1 container-gradient">
            <div class="center">
                <div class="text-box-logo">
                    <h1>caStudios.tech</h1>
                </div>
            </div>
        </div>
        <!-- 01 info-->
        <div class="col-xs-12 body-container2 ">
            <div class="col-md-6 sub-container1a">
                <div class="text-box">
                    <div class="text-size">
                        <p>This page is about: caStudios Tech</p>
                        <p>On this page you will find all kind of different things.
                            If you use the chatbot you can asked questions about who I am and other things.
                            There is Also a way on how/if you want to reach out to me under "Contact".</p><br>
                        <p>The page is about my interest of learning new things and show who I am and where I want to be.
                            On the page "Portfolio" you will be able to explore more but have in mind that there are continuously updates. or you can use the famouse chatbot</p>
                    </div>
                </div>
                <br>
                <div>
                    <divider [borderColor]="'rgb(207, 207, 207)'"></divider>
                </div>
                <div class="text-box">
                    <p>This feature will soon be available!</p>
                    <a href="Login">Login </a>
                </div>
                <div class="text-box-logo"></div>
            </div>
            <div class="col-md-6 sub-container1b">
                <div class="text-box-nr">
                    <h2>01</h2>
                </div>
            </div>
        </div>
        <!-- 02 Portfolio Cards -->
        <div class="col-xs-12 body-container3">
            <div class="center-card">
                <div class="text-box">
                    <div style="display: inline-flex;">
                        <a href="Chatbot">
                            <Card
                                [title]="item[0].title"
                                [imgSrc]="item[0].imgSrc"
                                [backgroundColor]="'rgb(0, 0, 0)'">
                            </Card>
                        </a>
                        <a href="Portfolio">
                            <Card
                                [title]="item[1].title"
                                [imgSrc]="item[1].imgSrc"
                                [backgroundColor]="'rgb(0, 0, 0)'">
                            </Card>
                        </a>
                        <a href="Contact">
                            <Card
                                [title]="item[2].title"
                                [imgSrc]="item[2].imgSrc"
                                [backgroundColor]="'rgb(0, 0, 0)'">
                            </Card>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Carousel remove -->
        <div class="col-xs-12 body-container4 container-gradient1">
            <div class="col-md-6 sub-container1a">
                <div class="text-box-nr">
                    <h2>02</h2>
                </div>
                <div class="text-box-logo">
                    <h3><a href="Portfolio">Portfolio</a></h3>
                </div>
            </div>
            <div class="col-md-6 sub-container1b"></div>
        </div>
        <!-- 03 Contact -->
        <div class="col-xs-12 body-container5">
            <div class="col-md-6 sub-container1a">
                <div class="background-overlay">
                    <img src="../../assets/images/home/MeFuture.jpeg">
                </div>
                <div class="text-box-logo"></div>
            </div>
            <div class="col-md-6 sub-container1b">
                <div class="text-box-nr">
                    <h2>03</h2>
                </div>
                <div class="text-box-logo">
                    <h3><a href="Contact">Contact</a></h3>
                </div>
            </div>
        </div>
    </div>
</div>