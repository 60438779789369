import { BrowserModule } from '@angular/platform-browser';
import { Card } from './card/card.component';
import { NgModule } from '@angular/core';
import { DividerComponent } from './divider/divider.component';


@NgModule ({
    declarations: [
        Card,
        DividerComponent
    ],
    imports: [
        BrowserModule
    ],
    exports: [
        Card,
        DividerComponent
    ],
    providers: [],
    bootstrap: [FabricModule]
})

export class FabricModule {}
