<Header></Header>
<router-outlet></router-outlet>
<button class="show-chat-button" tabindex="0" type="button" aria-label="chat" title="Chat" (click)="toggleChat()">
  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-message" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4"></path>
    <line x1="8" y1="9" x2="16" y2="9"></line>
    <line x1="8" y1="13" x2="14" y2="13"></line></svg>
</button>
<div class="chatbot-body" [ngClass]="chatVisible ? 'chatbot-visible' : ''">
  <div class="chatbot-inner-body">
    <div class="cloud">
      <div class="messagelist">
        <div *ngFor="let chat of chatHistory; index as i">
          <div class="usermessage" [ngClass]="i % 2 == 0 ? 'robotMsg' : ''" style="display: flex;">
            <img src="{{getSource(chat)}}" alt="AI" width="30" height="30" class="boticon" src="/assets/images/chatbot/robot.png">
            <div style="display: flex;">
              <div class="markdownanswer">
                <p>{{chat.message}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chatbot-divider"></div>
    <div class="question-switch-section">
      <input [(ngModel)]="askChris" class="question-switch" type="checkbox" />
      <div class="question-switch-background" [ngClass]="askChris ? 'question-switch-background-ask-chris' : ''">{{getQuestionText()}}</div>
    </div>
    <div class="center">
      <div class="chatbot-question-body" [ngClass]="loading ? 'chatbox-loading' : ''">
        <textarea class="chatbot-question-box"
            [disabled]="loading"
            [(ngModel)]="question"
            id="userInput"
            name="userInput"
            placeholder="{{getPlaceHolder()}}"
            (keyup.enter)="submitQuestion()">
        </textarea>
        <div *ngIf="loading" class="lds-dual-ring"></div>
        <div *ngIf="!loading" class="chatbot-button-body">
          <button class="" (click)="submitQuestion()">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-send"
                 width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#1e88e5"
                 fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="10" y1="14" x2="21" y2="3"></line>
              <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
