import {Component} from '@angular/core';
import {ApiService} from './api/api.services';

export interface Chat {
  message: string;
  fromBot: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})

export class AppComponent {
  title = 'caStudios';
  question = '';
  chatHistory = [] as Chat[];
  chatVisible = false;
  loading = false;
  askChris = false;

  constructor(private readonly api: ApiService) {
    this.chatHistory.push({message: 'Hi there! How can I help?', fromBot: true});
  }

  toggleChat(): void {
    this.chatVisible = !this.chatVisible;
  }

  getPlaceHolder(): string {
    return this.loading ? 'Gathering information...' : 'Type your question...';
  }

  submitQuestion(): void {
    this.chatHistory.push({message: this.question, fromBot: false});
    setTimeout(() => this.scrollToBottom(), 100);
    this.loading = true;
    if (this.askChris) {
      setTimeout(() => this.scrollToBottom(), 100);
      this.api.queryChris({question: this.question}).then((response) => {
        this.chatHistory.push({message: '', fromBot: true});
        const arrayIndex = this.chatHistory.length - 1;
        const interval = 100;
        console.log (response);
        response.text.split(' ').forEach((word: string, index: number, array: string[]) => {
          setTimeout(() => {
            this.chatHistory[arrayIndex].message = this.chatHistory[arrayIndex].message + ' ' + word;
            this.scrollToBottom();
            if (index === array.length - 1) {
              this.loading = false;
            }
          }, index * interval);
        });
      });
      this.question = '';
    } else {
      setTimeout(() => this.scrollToBottom(), 100);
      this.api.queryInternet({question: this.question}).then((response) => {
        this.chatHistory.push({message: '', fromBot: true});
        const arrayIndex = this.chatHistory.length - 1;
        const interval = 100;
        response.split(' ').forEach((word: string, index: number, array: string[]) => {
          setTimeout(() => {
            this.chatHistory[arrayIndex].message = this.chatHistory[arrayIndex].message + ' ' + word;
            this.scrollToBottom();
            if (index === array.length - 1) {
              this.loading = false;
            }
          }, index * interval);
        });
      });
      this.question = '';
    }
    setTimeout(() => this.scrollToBottom(), 100);
  }

  getSource(chat: Chat): string {
    return chat.fromBot ? '/assets/images/chatbot/robot.png' : '/assets/images/chatbot/user.png';
  }

  scrollToBottom(): void {
    const messageList = document.getElementsByClassName('messagelist')[0];
    messageList.scrollTop = messageList.scrollHeight + 300;
  }

  getQuestionText(): string {
    return this.askChris ? 'ASK CHRIS' : 'ASK THE INTERNET';
  }
}

