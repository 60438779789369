<div class="header">
    <div class="row no-margins">   
        <div class="col-xs-12 header-links">
            <div class="header-navbar full-height">
                <ul class="header-navbar-ul full-height">
                    <a href="javascript: void(0);" [routerLink]="['/']" (click)="setActiveTab('home')">
                        <li class="full-height" [ngClass]="{'active-color': activeTab === 'home'}">Home</li>
                    </a>
                    <a href="javascript: void(0);" [routerLink]="['Chatbot']" (click)="setActiveTab('chatbot')">
                        <li class="full-height" [ngClass]="{'active-color': activeTab === 'chatbot'}">Chatbot</li>
                    </a >
                    <a href="javascript: void(0);" [routerLink]="['Portfolio']" (click)="setActiveTab('portfolio')">
                        <li class="full-height" [ngClass]="{'active-color': activeTab === 'portfolio'}">Portfolio</li>
                    </a>
                    <a href="javascript: void(0);" [routerLink]="['Contact']" (click)="setActiveTab('contact')">
                        <li class="full-height" [ngClass]="{'active-color': activeTab === 'contact'}">Contact</li>
                    </a>
                </ul>
            </div>
        </div> 
    </div>
</div>