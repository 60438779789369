<div class="body">
    <div class="element-row">
        <div class="col-xs-12 body-container1 container-gradient">
            <div class="col-sm-6 sub-container1a">
                <div class="text-box-image">
                    <img src="../../assets/images/contact/SuiteMeBW.jpeg" class="contact-image">
                </div>
            </div>
            <div class="col-sm-6 sub-container1b">
                <div class="column">
                    <div class="text-box">
                        <h1>Contact Information</h1>
                        <div class="text-css">
                            <p>Name: Christoffer Ahrenlöf</p>
                            <p>Title: Solution Architect</p>
                            <p>City: Stockholm, Sweden</p>
                            <p>Languanges: Swedish, English</p>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div>
                <divider [borderColor]="'rgb(207, 207, 207)'"></divider>
            </div>
        </div>
        <div class="col-xs-12 body-container1"></div>
    </div>
</div>