<div class="body">
    <div class="element-row">
        <div class="row no-margin">
            <div class="col-xs-12 body-top-container"></div>
        </div>
        <div class="col-xs-12 body-container1 container-gradient">
            <div class="col-sm-6">
                <h3>caStudios.tech - Report</h3>
            </div>
            <div class="col-sm-6"></div>
        </div>
        <div class="col-xs-12 body-container2">
            <div class="col-sm-6"></div>
            <div class="col-sm-6"></div>
        </div>
        <div class="col-xs-12 body-container3 container-gradient">
            <div class="col-sm-6"></div>
            <div class="col-sm-6"></div>
        </div>
        <div class="col-xs-12 body-container4">
            <div class="col-sm-6"></div>
            <div class="col-sm-6"></div>
        </div>
        <div class="col-xs-12 body-container5 container-gradient-back">
        </div>
    </div>
</div>
